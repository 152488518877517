import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import styled from '@emotion/styled'

const HomeLayoutContainer = styled(Container)`
  :focus {
    outline: none;
    border: none;
  }
`

export default function LandingLayout({ children }) {
  return (
    <>
      <HomeLayoutContainer
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          padding: '0 !important',
        }}
      >
        <Box
          sx={{
            height: '100%',
          }}
        >
          {children}
          <Box
            sx={{
              width: '100%',
              height: '100px',
              bottom: 0,
            }}
          />
        </Box>
      </HomeLayoutContainer>
    </>
  )
}
