const mailchimp = require('@mailchimp/mailchimp_transactional')(
  `${process.env.NEXT_PUBLIC_MAILCHIMP_API_KEY}`
)

// const message = {
//   from_email: 'franco@skipd.com',
//   subject: 'Probando Mailchimp',
//   html: '<body style="margin: 0;"> <a href=`http://localhost:3000/mvp/accept-invitation?tripPlanId=361b231b-1166-4ecd-9440-2b4194572e17&email=franco%2B23@skipd.com`>google </a>> </body>',
//   to: [
//     {
//       email: 'franco@skipd.com',
//       type: 'to',
//     },
//   ],
// }
//This is a demonstration of how messages should be

async function emailSender(message) {
  await mailchimp.messages.send({
    message,
  })
}

module.exports = {
  emailSender,
}
