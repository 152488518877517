import LandingPage from '../components/common/LandingPage'
import LandingLayout from '../components/layouts/LandingLayout'

const Landing = () => {
  return <LandingPage />
}

Landing.getLayout = (page) => <LandingLayout>{page}</LandingLayout>

export default Landing
