/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactElement, useEffect, useRef, useState } from 'react'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import Head from 'next/head'
import Image from 'next/image'
import Script from 'next/script'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from '@emotion/styled'
import inviteTestersTemplate from '../../../emailTemplates/inviteTesters'
import { emailSender } from '../../../utils/emailSending'

const LandingContainer = styled(Container)`
  display: flex;
  height: 100vh;
  width: 100vw;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0 13% !important;
  flex-direction: column;
  z-index: 200;
  @media (max-width: 500px) {
    padding: 0% !important;
  } ;
`
const FormContainer = styled.form`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
  z-index: 200;
`
const InputContainer = styled(Container)`
  display: flex;
  /* gap: 8px; */
  width: 60%;
  justify-content: center;
  padding: 0 !important;
  margin: 0;
  flex-direction: column;
  @media (max-width: 500px) {
    width: 100%;
  } ;
`

const StyledTextField = styled(TextField)`
  width: 460px;
  background-color: white;
  z-index: 200;

  input {
    background-color: white;
    color: #1d1c29;
    :focus {
      color: #1d1c29;
    }
    ::placeholder {
      color: #1d1c29;
      font-size: 14px;
    }
  }
  @media (max-width: 500px) {
    width: 250px;
  } ;
`

// const Button = styled.button`
//   height: 42px;
//   width: 72px;
//   background-color: ${colors.primary.main};
//   color: white;
//   box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
//     0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
// `

const LandingPage = (): ReactElement => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  const [sent, setSent] = useState(false)
  const recaptchaRef = useRef()

  useEffect(() => {
    if (errors.email) {
      alert(errors.email.message)
    }
  }, [errors])

  const onSubmit = async (data): Promise<void> => {
    if (!data.email) {
      alert('Please enter your email')
    } else {
      //@ts-ignore
      await recaptchaRef?.current?.executeAsync()

      const message = {
        from_email: 'info@skipd.com',
        subject: 'test',
        html: inviteTestersTemplate(),
        to: [
          {
            email: data.email,
            type: 'to',
          },
        ],
      }
      emailSender(message)
      setValue('email', '')
      setSent(true)
    }
  }
  return (
    <Container
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Head>
        <title>{`The Skip'd of Travel`}</title>
        <meta name="description" content="Skip'd corporation" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <Script src="https://www.google.com/recaptcha/api.js"></Script>
      </Head>

      <Image
        layout="fill"
        objectFit="contain"
        src="/images/background_globe.png"
        alt="dream big"
        priority
      />

      <LandingContainer>
        <Typography
          sx={{
            fontSize: '65px',
            fontWeight: '800',
            lineHeight: '65px',
            fontFamily: 'Archivo Black, sans-serif',
            zIndex: 200,
          }}
        >
          SKIP&apos;D
        </Typography>
        {sent ? (
          <Typography variant="h3">Thank you!</Typography>
        ) : (
          <>
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="body2"
                sx={{ textAlign: 'center', zIndex: 200 }}
              >
                The Skip&apos;d of Travel is completely transforming the travel
                economy to benefit travelers, communities and advisors.
              </Typography>
              <Typography
                variant="body2"
                sx={{ textAlign: 'center', zIndex: 200 }}
              >
                To receive notifications regarding progress on our private beta,
                please provide your email address. Thank you!
              </Typography>
            </Container>
            <FormContainer onSubmit={handleSubmit(onSubmit)}>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA}
              />
              <InputContainer>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <StyledTextField
                    placeholder="Enter your email address"
                    variant="outlined"
                    {...register('email', {
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email',
                      },
                    })}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ height: '40px', width: '72px' }}
                  >
                    Submit
                  </Button>
                </Box>

                <Typography
                  sx={{ fontSize: '12px', width: '100%', lineHeight: '14px' }}
                >
                  By providing your email address you are granting Skipd
                  Corporation sole and exclusive purpose of providing you
                  information regarding our private beta and other updates on
                  our progress. All emails sent to this address will have an
                  unsubscribe button should you no longer wish to receive
                  updates.
                </Typography>
              </InputContainer>
            </FormContainer>
          </>
        )}
      </LandingContainer>
    </Container>
  )
}

export default LandingPage
